import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful 
 * when you want to change your logo depending on the theme you are on. 
 */
export default function Logo({ fill }) {
  return (
<svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Desktop-HD" transform="translate(-311.000000, -283.000000)">
            <g id="logomark" transform="translate(311.000000, 283.000000)">
                <rect id="Rectangle" fill={fill} x="0" y="0" width="50" height="50" rx="2"></rect>
                <g id="bird" transform="translate(9.000000, 13.000000)" fill-rule="nonzero">
                    <g id="Group" fill={fill === '#000' ? '#fff' : '#000'}>
                        <polygon id="Shape" points="13.06 23.3928571 10.7 21.6666667 14.24 12.1230159 0.36 4.4047619 4.42 3.51190476 1.34 1.13095238 14.72 0.257936508 20.16 4.30555556 28 0.257936508 32.68 3.39285714 26.84 3.9484127 24.18 12.281746 13.86 19.4047619 15.08 24.9603175 11.46 24.5039683"></polygon>
                        <path d="M13.06,23.3928571 L10.68,21.6865079 L14.2,12.1031746 L14.22,12.1626984 L0.34,4.46428571 L0.18,4.38492063 L0.36,4.3452381 L4.4,3.45238095 L4.36,3.57142857 L1.28,1.19047619 L1.12,1.07142857 L1.32,1.0515873 L14.7,0.178571429 L14.74,0.178571429 L14.76,0.198412698 L20.2,4.22619048 L20.1,4.22619048 L27.94,0.198412698 L27.98,0.178571429 L28.02,0.198412698 L32.7,3.33333333 L32.88,3.45238095 L32.66,3.47222222 L26.8,4.02777778 L26.86,3.96825397 L24.2,12.3015873 L24.2,12.3214286 L24.18,12.3412698 L13.84,19.4444444 L13.86,19.4047619 L15.06,24.9603175 L15.06,24.9801587 L15.04,24.9801587 L11.42,24.5039683 L11.4,24.5039683 L11.42,24.484127 L13.06,23.3928571 Z M13.06,23.3928571 L11.46,24.5039683 L11.46,24.484127 L15.08,24.9206349 L15.06,24.9404762 L13.82,19.3849206 L13.82,19.3650794 L13.84,19.3452381 L24.16,12.202381 L24.14,12.2420635 L26.78,3.90873016 L26.8,3.86904762 L26.84,3.86904762 L32.7,3.31349206 L32.66,3.45238095 L27.98,0.337301587 L28.06,0.337301587 L20.2,4.38492063 L20.14,4.4047619 L20.1,4.36507937 L14.66,0.337301587 L14.72,0.357142857 L1.34,1.19047619 L1.38,1.0515873 L4.46,3.45238095 L4.58,3.5515873 L4.42,3.59126984 L0.38,4.46428571 L0.4,4.3452381 L14.26,12.0833333 L14.3,12.1031746 L14.28,12.1428571 L10.7,21.6666667 L10.7,21.6468254 L13.06,23.3928571 Z" id="Shape"></path>
                    </g>
                    <path d="M14.6377644,0.396825397 L15.9332549,4.09255445 L17.2287453,7.78828351 C18.0857621,10.2389274 18.9228482,12.7093345 19.779865,15.1797416 L19.8795181,15.4761905 L19.62042,15.2785579 L15.7937405,12.3733591 L11.967061,9.48792361 L8.1603122,6.56296158 L4.37349398,3.63799954 L8.24003469,6.48390855 L12.0866448,9.34958081 L15.9133242,12.2350163 L19.7400037,15.1204519 L19.6004894,15.2192681 C18.7634032,12.748861 17.9263171,10.2982171 17.1091616,7.82781002 L15.873463,4.13208097 L14.6377644,0.396825397 Z" id="Shape" fill="#000000"></path>
                    <path d="M20.2771084,4.36507937 C20.2771084,4.36507937 20.3551262,4.42520443 20.5111616,4.52541286 C20.6476927,4.62562129 20.8622414,4.7659131 21.115799,4.94628828 C21.3693567,5.12666346 21.6619232,5.32708033 21.9544897,5.54753888 C22.2665606,5.76799743 22.5786315,6.02853936 22.9102069,6.2690396 C23.2417822,6.50953984 23.5538531,6.77008177 23.8464196,7.01058201 C24.1389861,7.25108225 24.4120482,7.4715408 24.6656058,7.67195767 C24.899659,7.87237454 25.0947033,8.03270803 25.2312344,8.15295815 C25.3677654,8.27320827 25.4457831,8.33333333 25.4457831,8.33333333 C25.4457831,8.33333333 25.3677654,8.27320827 25.2117299,8.17299984 C25.0751989,8.07279141 24.8606501,7.9324996 24.6070925,7.75212442 C24.3535349,7.57174924 24.0609684,7.37133237 23.7684019,7.15087382 C23.456331,6.93041526 23.1442601,6.66987334 22.8126847,6.4293731 C22.4811093,6.18887286 22.1690384,5.92833093 21.8764719,5.68783069 C21.5839054,5.44733045 21.3108434,5.22687189 21.0572857,5.02645503 C20.8232326,4.82603816 20.6281882,4.66570467 20.4916572,4.54545455 C20.3551262,4.42520443 20.2771084,4.36507937 20.2771084,4.36507937 Z" id="Shape" fill="#000000"></path>
                    <path d="M28.2289157,0 C28.2289157,0 28.2289157,0.0598229241 28.1877856,0.179468772 C28.1672206,0.299114621 28.1260906,0.458642418 28.0849605,0.638111191 C28.0027005,1.01698971 27.8587453,1.51551408 27.7147902,2.01403845 C27.5708351,2.51256281 27.4063149,3.01108718 27.2623598,3.37002473 C27.2006647,3.5494935 27.1389697,3.7090213 27.0978396,3.80872617 C27.0567096,3.90843104 27.0361446,3.96825397 27.0361446,3.96825397 C27.0361446,3.96825397 27.0361446,3.90843104 27.0772746,3.7887852 C27.0978396,3.66913935 27.1389697,3.50961155 27.1800997,3.33014278 C27.2623598,2.95126426 27.4063149,2.45273989 27.55027,1.95421552 C27.6942252,1.45569115 27.8587453,0.957166786 28.0027005,0.598229241 C28.0643955,0.418760469 28.1260906,0.259232671 28.1672206,0.159527798 C28.2083506,0.0598229241 28.2289157,0 28.2289157,0 Z" id="Shape" fill="#000000"></path>
                    <path d="M13.1204819,23.4126984 C13.1204819,23.4126984 13.2596386,23.4930681 13.4584337,23.6136227 C13.6572289,23.7341772 13.9156627,23.9351015 14.1740964,24.1159333 C14.4325301,24.3168575 14.6710843,24.5177818 14.8301205,24.6986136 C15.0090361,24.859353 15.1084337,25 15.1084337,25 C15.1084337,25 14.9692771,24.9196303 14.7704819,24.7990757 C14.5716867,24.6785212 14.313253,24.4775969 14.0548193,24.2967651 C13.7963855,24.0958409 13.5578313,23.8949166 13.3987952,23.7140848 C13.2198795,23.533253 13.1204819,23.4126984 13.1204819,23.4126984 Z" id="Shape" fill="#000000"></path>
                    <path d="M10.7349398,21.8253968 L11.7289157,20.8947959 C12.0668675,20.5779956 12.4048193,20.2809953 12.7427711,19.983995 C13.4186747,19.3701944 14.0945783,18.7563938 14.7704819,18.1623932 L16.8180723,16.3605914 L18.8656627,14.5785896 L18.8855422,14.7171897 C18.110241,14.3211893 17.3349398,13.9251889 16.5596386,13.5291885 C15.7843373,13.1331881 15.0090361,12.7173877 14.2536145,12.3015873 C15.0487952,12.6777877 15.8240964,13.034188 16.6192771,13.4103884 C17.3945783,13.7865888 18.189759,14.1627892 18.9650602,14.5587896 L19.0843373,14.6181896 L18.9849398,14.6973897 L16.9373494,16.4991915 L14.889759,18.2811933 C14.2138554,18.8751939 13.5180723,19.4691945 12.8222892,20.0631951 C12.4843373,20.3601954 12.126506,20.6571957 11.7885542,20.9343959 L10.7349398,21.8253968 Z" id="Shape" fill="#000000"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
  );
}
